
@import '~@angular/material/theming';
// import our custom theme
@import 'material-theme.scss';

// always include only once per project
@include mat-core($general-typography);


html, body {
  margin: 0;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
}

// specify theme class eg: <body class="app-theme"> ... </body>
.app-theme {

  // use our theme with angular-material-theme mixin
  @include angular-material-theme($default-theme);
  @include generics($default-theme);
  @include customCdkOverlayStylings();
}

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  // src: url('./assets/fonts/Material-Icons-webfont.woff2') format('woff2');
  src: url('./assets/fonts/MaterialIconsOutlined-Regular.woff2') format('woff2');

}

/* class for forms */
.form-title {
  color: #58b194;
}

/* snack-bar style */
.snack-bar-error {
  background: darkred !important;
  color: white !important;
}

.snack-bar-success {
  background: #58b194 !important;
  color: black !important;
}
.mat-simple-snackbar-action{
  color: inherit !important;
}
