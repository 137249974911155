// define 3 theme color
// mat-palette accepts $palette-name, main, lighter and darker variants
$md-siurgence: (
    50 : #ebf6f2,
    100 : #cde8df,
    200 : #acd8ca,
    300 : #8ac8b4,
    400 : #71bda4,
    500 : #58b194,
    600 : #50aa8c,
    700 : #47a181,
    800 : #3d9877,
    900 : #2d8865,
    A100 : #ceffeb,
    A200 : #9bffd7,
    A400 : #68ffc2,
    A700 : #4effb8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-mcgpalette0: (
    50 : #ebf6f2,
    100 : #cde8df,
    200 : #acd8ca,
    300 : #8ac8b4,
    400 : #71bda4,
    500 : #58b194,
    600 : #50aa8c,
    700 : #47a181,
    800 : #3d9877,
    900 : #2d8865,
    A100 : #ceffeb,
    A200 : #9bffd7,
    A400 : #68ffc2,
    A700 : #4effb8,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-siurgence-accent: (
    50 : #f8f8f8,
    100 : #ededed,
    200 : #e2e2e2,
    300 : #d6d6d6,
    400 : #cdcdcd,
    500 : #c4c4c4,
    600 : #bebebe,
    700 : #b6b6b6,
    800 : #afafaf,
    900 : #a2a2a2,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffe5e5,
    A700 : #ffcccc,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);


$default-theme-primary: mat-palette($md-mcgpalette0, 500, 300, 900);
$default-theme-accent: mat-palette($md-siurgence-accent);
$default-theme-warn: mat-palette($mat-deep-orange);

// create theme (use mat-dark-theme for themes with dark backgrounds)
$default-theme: mat-light-theme($default-theme-primary,
  $default-theme-accent,
  $default-theme-warn,
);

// Update background color of theme
$custom-background-color: #ffffff;
$background: map-get($default-theme, background);
$background: map_merge($background, (background: $custom-background-color));
$default-theme: map_merge($default-theme, (background: $background));

/* Custom typography */
$general-typography: mat-typography-config($font-family: 'Roboto, "Helvetica Neue", sans-serif',
  $display-4: mat-typography-level(112px, 112px, 300),
  $display-3: mat-typography-level(56px, 56px, 400),
  $display-2: mat-typography-level(45px, 48px, 400),
  $display-1: mat-typography-level(34px, 40px, 400),
  $headline: mat-typography-level(24px, 32px, 600),
  $title: mat-typography-level(20px, 32px, 600),
  $subheading-2: mat-typography-level(18px, 28px, 600),
  $subheading-1: mat-typography-level(16px, 24px, 600),
  $body-2: mat-typography-level(14px, 24px, 500),
  $body-1: mat-typography-level(14px, 20px, 400),
  $caption: mat-typography-level(12px, 20px, 400),
  $button: mat-typography-level(14px, 14px, 500),
  $input: mat-typography-level(14px, 1.325, 400));

/* Custom theme for generic details on the app */
@mixin generics($default-theme) {

  /* material icons default style*/
  .material-icons {
    font-family: 'Material Icons', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  .pointer {
    cursor: pointer;
  }

  .module-title {
    text-transform: uppercase;
    font-size: 35px;
    font-weight: bold;
  }

  .module-subtitle {
    text-transform: uppercase;
    font-size: 25px;
    font-weight: bold;
  }

  .data-information {
    font-size: 18px;
    margin-left: 20px;
  }
}

@mixin customCdkOverlayStylings() {
  .mat-tooltip {
    background: black;
    padding: 10px;
    font-size: 12px;
  }

  .initialize-spinner.mat-progress-spinner circle {
    stroke: #009688;
  }

  .default-resume-snackbar .mat-simple-snackbar-action {
    color: #4db6ac;
  }

  .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title, .app-theme .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
    color: black
  }
}
